import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

const Partnerships = ({ partnerships, partnershipsImages }) => {
  const { t } = useI18next();
  const logoStyles = partnershipsImages.map((partnershipsImage) => {
    const { height, width } = partnershipsImage.localFile.childImageSharp.fixed;
    const isHight = height > width;
    const style = {
      margin: "0.2em 0.5em",
      // limit the smallest dimension
      width: isHight ? "6rem" : `${Math.round((60 * width) / height) / 10}rem`,
      maxHeight: isHight ? "unset" : "6rem",
    };
    return style;
  });
  return (
    <Container maxWidth="lg" sx={{ my: "auto", mx: "auto", padding: 0 }}>
      <Typography variant="bodyStrong">{t("course.partnerships")}</Typography>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {partnerships.map((content, i) => (
          <GatsbyImage
            key={`partnership__${i}`}
            alt={content.logo.alternativeText}
            image={
              partnershipsImages[i].localFile.childImageSharp.gatsbyImageData
            }
            style={
              logoStyles[i] || {
                margin: "0.2em 0.5em",
                width: "6rem",
                maxHeight: "6rem",
              }
            }
          />
        ))}
      </Box>
    </Container>
  );
};

export default Partnerships;
