import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Partnerships from "../../components/bricks/partnerships";
import { Container, Typography, Chip } from "@mui/material";
import Layout from "../../components/layout";
import Breadcrumb from "../../components/breadcrumbs/breadcrumb";
import { formatAlternatePath } from "../../utils/alternate-lang";
import { useI18next } from "gatsby-plugin-react-i18next";
import BrickImageText from "../../components/bricks/image-text";
import { useTheme } from "@emotion/react";
import BrickVideoText from "../../components/bricks/video-text";
import Sticky from "../../components/sticky";
import DoneIcon from "@mui/icons-material/Done";
import {
  findNextLesson,
  getCourseLessons,
  hasBeenCompleted,
} from "../../utils/progress-tracking";
import { gql, useMutation } from "@apollo/client";
import PrivateRoute from "../../components/private-route";
import Brick from "../../components/bricks/bricks";
import QuizzResults from "../../components/quizz-results";
import Quizz from "../../components/quizz";
import { getUser, gtmPushUserInfo } from "../../services/auth";
import Lesson from "../../components/lesson";
import SyllabusLesson from "../../components/syllabusLesson";
import BrickNewsletter from "../../components/bricks/newsletter";
import BrickRichText from "../../components/bricks/rich-text";

export const query = graphql`
  fragment StrapiNewsletterCoursesFragment on StrapiNewsletterCourses {
    Newsletter {
      title
      subtitle
      background {
        alternativeText
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  query Course($Slug: String!, $language: String!) {
    course: strapiCourse(Slug: { eq: $Slug }) {
      Slug
      AlternateSlug
      Title
      metaDescription
      description {
        richText
      }
      videoId
      hours
      minutes
      credits
      CME
      therapeutical_area {
        Name
      }
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContent
      pageContentSeeMoreThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentSeeMoreEventsThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentSeeMoreCoursesThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      Thumbnail {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      partnerships
      partnershipsImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fixed {
              height
              width
            }
          }
        }
      }
      Modules {
        title
        lessons {
          Slug
          videoId
          title
          description
          download {
            title
            files {
              name
              localFile {
                publicURL
              }
            }
          }
        }
      }
      downloadLessonMaterial
      quizz {
        Slug
        successScore
        Questions {
          answer1
          answer2
          answer3
          answer4
          answer5
          question
          strapi_component
          correct1
          correct2
          correct3
          correct4
          correct5
          id
        }
        Title
        id
      }
      diploma {
        name
        localFile {
          publicURL
        }
      }
    }
    strapiNewsletterCoursesEn: strapiNewsletterCourses(locale: { eq: "en" }) {
      ...StrapiNewsletterCoursesFragment
    }
    strapiNewsletterCoursesEs: strapiNewsletterCourses(
      locale: { eq: "es-PA" }
    ) {
      ...StrapiNewsletterCoursesFragment
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CourseComponent = ({ data }) => {
  const theme = useTheme();

  const isCourseCompleted =
    getUser().progress &&
    getUser().progress[data.course.Slug] &&
    getUser().progress[data.course.Slug].includes(data.course.quizz?.Slug);

  const { t, language } = useI18next();
  const alternateLang = language === "en" ? "es" : "en";
  const newsletter =
    language === "en"
      ? data.strapiNewsletterCoursesEn
      : data.strapiNewsletterCoursesEs;
  const seo = {
    metaTitle: data.course.Title,
    metaDescription: (data.course.metaDescription || "").split("\n").join(" "),
    linkAlternateHref: data.course.AlternateSlug
      ? `${formatAlternatePath(
          data.course.AlternateSlug,
          "courses",
          alternateLang
        )}`
      : null,
    linkHrefLang: alternateLang,
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Courses",
      page_ta: data.course.therapeutical_area.Name,
      page_type: "Courses",
    });
    gtmPushUserInfo();
  }, [language, data]);

  const courseLessons = getCourseLessons(data.course);
  const [currentLesson, setCurrentLesson] = useState(
    findNextLesson(data.course, courseLessons)
  );
  const [displayLesson, setDisplayLesson] = useState(false);
  const [displayQuizz, setDisplayQuizz] = useState(false);
  let checkedAnswers = {};
  const startOrResumeCourse = () => {
    window.scrollTo(0, 0);
    if (
      courseLessons.indexOf(currentLesson) !== courseLessons.length - 1 ||
      courseLessons.length === 1 ||
      !hasBeenCompleted(currentLesson, data.course, getUser().progress)
    ) {
      setDisplayLesson(true);
    } else {
      setDisplayQuizz(true);
    }
  };

  const nextButtonDisabled =
    !isCourseCompleted &&
    !hasBeenCompleted(currentLesson, data.course, getUser().progress);
  const UPDATE_PROGRESS = gql`
    mutation ($id: ID!, $progress: JSON!, $quizzResults: JSON) {
      updateUser(
        input: {
          where: { id: $id }
          data: { progress: $progress, quizzResults: $quizzResults }
        }
      ) {
        user {
          id
          userLastName
          userFirstName
          country
          profession
          areasOfExpertise
          email
          progress
          quizzResults
          subscribedEvents
          subscribedNewsletter
        }
      }
    }
  `;
  const [updateUser, { loading: loadingUpdateProgress }] =
    useMutation(UPDATE_PROGRESS);

  const previous = () => {
    window.scrollTo(0, 0);
    if (displayQuizz) {
      setCurrentLesson(courseLessons[courseLessons.length - 1]);
      setDisplayLesson(true);
      setDisplayQuizz(false);
    } else {
      courseLessons.indexOf(currentLesson) === 0
        ? setDisplayLesson(false)
        : setCurrentLesson(
            courseLessons[courseLessons.indexOf(currentLesson) - 1]
          );
    }
  };

  const [quizzHasBeenSubmitted, setQuizzHasBeenSubmitted] = useState(false);
  const [quizzScore, setQuizzScore] = useState();

  if (!data) return null;
  return (
    <Layout
      seo={seo}
      alternateHref={
        data.course.AlternateSlug
          ? formatAlternatePath(data.course.AlternateSlug, "courses")
          : null
      }
    >
      {/* DISPLAY COURSE SUMMARY */}
      {!displayLesson && !displayQuizz && (
        <div>
          {data.course.headerImage && (
            <BrickImageText
              title=""
              gatsbyImageData={
                data.course.headerImage.localFile.childImageSharp
                  .gatsbyImageData
              }
              alternativeText={data.course.headerImage.alternativeText}
              mobileImageStyle={{
                maxHeight: "200px",
              }}
              isHeader={true}
            />
          )}
          <Container
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
            maxWidth="xl"
          >
            <Breadcrumb
              title={data.course.Title}
              parentTitle={t(`nav.courses`)}
              parentLink="/courses"
              color="red"
            />
            <Container
              sx={{
                marginTop: 4,
                marginBottom: { xs: 1, md: 4 },
                mx: "auto",
              }}
              style={{ padding: 0 }}
              maxWidth="lg"
            >
              {isCourseCompleted && (
                <Chip
                  variant="contained"
                  label={t("course.completed")}
                  sx={{ my: 2 }}
                  icon={
                    <DoneIcon style={{ color: "white", marginLeft: "10px" }} />
                  }
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "900",
                    lineHeight: "19px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    backgroundColor: theme.palette.servier.green,
                    color: "white",
                    textTransform: "uppercase",
                  }}
                />
              )}
              <Typography variant="h1">{data.course.Title}</Typography>
            </Container>
            {!!data.course.partnerships.length && (
              <Partnerships
                partnerships={data.course.partnerships}
                partnershipsImages={data.course.partnershipsImages}
              />
            )}
          </Container>
          {data.course.videoId && (
            <BrickVideoText videoId={data.course.videoId} />
          )}
          <Container
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
            maxWidth="xl"
          >
            {data.course.description && (
              <div>
                <Container
                  style={{ padding: 0 }}
                  sx={{ my: 4, mx: "auto" }}
                  maxWidth="lg"
                >
                  <Typography variant="h3">
                    {t("course.aboutTheCourse")}
                  </Typography>
                  <hr
                    color={theme.palette.servier.lightBlue}
                    style={{
                      width: "65px",
                      height: "8px",
                      marginBottom: "2em",
                      marginLeft: 0,
                      marginTop: 0,
                    }}
                  ></hr>
                  <BrickRichText
                    style={{ padding: 0 }}
                    text={data.course.description.richText}
                  />
                </Container>
              </div>
            )}
            {!!data.course.Modules?.length && (
              <SyllabusLesson
                course={data.course}
                isInCourseSummary={true}
                currentLesson={currentLesson}
                setCurrentLesson={setCurrentLesson}
                setDisplayQuizz={setDisplayQuizz}
                setDisplayLesson={setDisplayLesson}
              />
            )}
          </Container>
          {data.course.pageContent.map((content, i) => (
            <Brick
              key={`content__${i}`}
              content={content}
              seeMoreThumbsContent={data.course.pageContentSeeMoreThumbs[i]}
              seeMoreEventsThumbsContent={
                data.course.pageContentSeeMoreEventsThumbs[i]
              }
              seeMoreCoursesThumbsContent={
                data.course.pageContentSeeMoreCoursesThumbs[i]
              }
            />
          ))}
          {newsletter && (
            <BrickNewsletter
              title={newsletter.Newsletter.title}
              subtitle={newsletter.Newsletter.subtitle}
              buttonText={newsletter.Newsletter.buttonText}
              backgroundImageData={
                newsletter.backgroundImage[0].localFile.childImageSharp
                  .gatsbyImageData
              }
              alternativeText={newsletter.Newsletter.background.alternativeText}
              style={{ padding: 0 }}
            />
          )}
          <div
            style={{
              zIndex: 1,
              position: "-webkit-sticky" /* Safari */,
              position: "sticky",
              bottom: 0,
            }}
          >
            <Sticky
              course={data.course}
              startOrResumeCourse={startOrResumeCourse}
            />
          </div>
        </div>
      )}
      {/* DISPLAY CURRENT LESSON  */}
      {displayLesson && currentLesson && (
        <Container
          maxWidth="lg"
          sx={{ my: { xs: 1, lg: 4 }, mx: "auto" }}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
            <Breadcrumb
              title={currentLesson.title}
              grandParentTitle={t(`nav.courses`)}
              grandParentLink="/courses"
              parentTitle={data.course.Title}
              parentLink={`/courses/${data.course.Slug}`}
            />
          </Container>
          <Lesson
            course={data.course}
            currentLesson={currentLesson}
            isCourseCompleted={isCourseCompleted}
            previous={previous}
            setDisplayLesson={setDisplayLesson}
            setDisplayQuizz={setDisplayQuizz}
            setCurrentLesson={setCurrentLesson}
            updateUser={updateUser}
            nextButton={nextButtonDisabled}
          />
        </Container>
      )}
      {/* DISPLAY QUIZZ */}
      {data.course.quizz && displayQuizz && !quizzHasBeenSubmitted && (
        <Container
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
          maxWidth="lg"
          sx={{ my: { xs: 1, lg: 4 }, mx: "auto" }}
        >
          <Breadcrumb
            title={data.course.quizz.Title}
            grandParentTitle={t(`nav.courses`)}
            grandParentLink="/courses"
            parentTitle={data.course.Title}
            parentLink={`/courses/${data.course.Slug}`}
          />
          <Quizz
            course={data.course}
            setQuizzHasBeenSubmitted={setQuizzHasBeenSubmitted}
            setQuizzScore={setQuizzScore}
            updateUser={updateUser}
            previous={previous}
            isCourseCompleted={isCourseCompleted}
            checkedAnswers={checkedAnswers}
          />
        </Container>
      )}
      {/* DISPLAY QUIZZ RESULTS */}
      {displayQuizz && quizzHasBeenSubmitted && (
        <Container maxWidth="lg" sx={{ my: { xs: 1, lg: 4 }, mx: "auto" }}>
          <Breadcrumb
            title={data.course.quizz.Title}
            grandParentTitle={t(`nav.courses`)}
            grandParentLink="/courses"
            parentTitle={data.course.Title}
            parentLink={`/courses/${data.course.Slug}`}
          />
          <QuizzResults
            setDisplayLesson={setDisplayLesson}
            setDisplayQuizz={setDisplayQuizz}
            setQuizzHasBeenSubmitted={setQuizzHasBeenSubmitted}
            course={data.course}
            isCourseCompleted={isCourseCompleted}
            quizzScore={quizzScore}
          />
        </Container>
      )}
    </Layout>
  );
};

const Course = ({ data }) => {
  return <PrivateRoute component={CourseComponent} data={data} />;
};

export default Course;
