import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Chip,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import DoneIcon from "@mui/icons-material/Done";
import SyllabusLesson from "./syllabusLesson";
import BrickVideoText from "./bricks/video-text";
import { getUser, setUserFromUpdateData } from "../services/auth";
import {
  getCourseLessons,
  getUpdatedProgress,
  hasBeenCompleted,
} from "../utils/progress-tracking";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BrickDownloadContent from "./bricks/download-content";
import ProgressBar from "./progress-bar";

const Lesson = ({
  course,
  currentLesson,
  isCourseCompleted,
  previous,
  setDisplayLesson,
  setDisplayQuizz,
  setCurrentLesson,
  updateUser,
  nextButton,
}) => {
  const theme = useTheme();
  const { t } = useI18next();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const progress = getUser().progress;
  const courseLessons = getCourseLessons(course);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(nextButton);
  const onEndLessonVideo = () => setNextButtonDisabled(false);

  const next = () => {
    window.scrollTo(0, 0);
    updateUser({
      variables: {
        id: getUser().id,
        progress: getUpdatedProgress(
          getUser().progress,
          course.Slug,
          currentLesson.Slug
        ),
        quizzResults: getUser().quizzResults,
      },
      headers: {
        Authorization: `Bearer ${getUser().token}`,
      },
    })
      .then((data) => {
        // Handle success.
        setUserFromUpdateData(data, getUser().token);
        if (courseLessons.indexOf(currentLesson) === courseLessons.length - 1) {
          setDisplayLesson(false);
          setDisplayQuizz(true);
        } else {
          const nextLesson =
            courseLessons[courseLessons.indexOf(currentLesson) + 1];
          setCurrentLesson(nextLesson);
          if (!hasBeenCompleted(nextLesson, course, getUser().progress)) {
            setNextButtonDisabled(true);
          }
          return;
        }
      })
      .catch((error) => {
        // Handle error.
        console.log(
          "update user progress : an error occurred:",
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message
        );
      });
  };

  const getNumberLessonsCompleted = () => {
    if (progress && progress[course.Slug]) {
      return progress[course.Slug].includes(course.quizz?.Slug)
        ? progress[course.Slug].length - 1
        : progress[course.Slug].length;
    }
    return 0;
  };
  const hasCourseBeenShorten = () => {
    if (getNumberLessonsCompleted() > getCourseLessons(course).length) {
      console.log(
        "this course has been shorten, the number of lessons is now : ",
        getCourseLessons(course).length
      );
      return true;
    }
    return false;
  };
  return (
    <div>
      {isCourseCompleted && (
        <Chip
          variant="contained"
          label={t("course.completed")}
          sx={{ my: 2, px: 2 }}
          icon={<DoneIcon style={{ color: "white", marginLeft: "10px" }} />}
          style={{
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "900",
            lineHeight: "19px",
            letterSpacing: "0em",
            textAlign: "left",
            backgroundColor: theme.palette.servier.green,
            color: "white",
            textTransform: "uppercase",
          }}
        />
      )}
      <Container
        sx={{ marginTop: { md: 4, xs: 1 }, marginBottom: 1 }}
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: isMobile ? "space-between" : "flex-end",
            alignItems: "space-between",
            marginBottom: "30px",
          }}
        >
          <Button
            style={{ textTransform: "none" }}
            onClick={previous}
            startIcon={
              <ArrowBackIosNew
                style={{ color: theme.palette.grey.servier_1 }}
              />
            }
          >
            <Typography
              variant="textButton"
              style={{ color: theme.palette.grey.servier_1 }}
            >
              {t("course.previous")}
            </Typography>
          </Button>
          <Button
            style={{
              textTransform: "none",
            }}
            disabled={nextButtonDisabled}
            endIcon={
              <ArrowForwardIosIcon
                style={{
                  color: nextButtonDisabled
                    ? theme.palette.grey.servier_4
                    : theme.palette.grey.servier_1,
                }}
              />
            }
            onClick={next}
          >
            <Typography
              variant="textButton"
              style={{
                color: nextButtonDisabled
                  ? theme.palette.grey.servier_4
                  : theme.palette.grey.servier_1,
              }}
            >
              {t("course.next")}
            </Typography>
          </Button>
        </div>
        <Typography variant="h2">{currentLesson.title}</Typography>
      </Container>
      <BrickVideoText
        videoId={currentLesson.videoId}
        onEndLessonVideo={onEndLessonVideo}
      />
      <Container
        sx={{ my: 1 }}
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">{currentLesson.description}</Typography>
      </Container>
      {currentLesson.download && (
        <BrickDownloadContent
          title={currentLesson.download.title}
          fileRefs={currentLesson.download.files}
          files={currentLesson.download.files}
        />
      )}
      <Container
        sx={{ marginTop: 0, marginBottom: { md: 4, xs: 1 } }}
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ marginTop: "2em" }}>
          <Typography variant="subtitle1">{t("course.progress")}</Typography>
          <ProgressBar
            progress={
              getUser().progress && getUser().progress[course.Slug]
                ? getUser().progress[course.Slug].length
                : 0
            }
            total={getCourseLessons(course).length}
          />
          <Typography variant="body1" style={{ marginBottom: "2em" }}>
            {hasCourseBeenShorten()
              ? getCourseLessons(course).length
              : getNumberLessonsCompleted()}{" "}
            {t("course.outOf")} {getCourseLessons(course).length}{" "}
            {t("course.completedSections")}
          </Typography>
        </Box>
      </Container>
      <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <SyllabusLesson
          course={course}
          currentLesson={currentLesson}
          setCurrentLesson={setCurrentLesson}
          setDisplayQuizz={setDisplayQuizz}
          setDisplayLesson={setDisplayLesson}
        />
      </Container>
    </div>
  );
};

export default Lesson;
