import { getUser } from "../services/auth";

export function getCourseLessons(course) {
  if (!course || !course.Modules) {
    return [];
  }
  let lessons = [];
  for (let i = 0; i < course.Modules.length; i++) {
    if (course.Modules[i].lessons) {
      lessons = lessons.concat(course.Modules[i].lessons);
    }
  }
  return lessons;
}

export function getUpdatedProgress(progress, courseSlug, lessonSlug) {
  const keys = Object.keys(progress ?? []);
  if (!progress) {
    return JSON.parse(`{"${courseSlug}":["${lessonSlug}"]}`);
  }
  if (keys.includes(courseSlug)) {
    if (!progress[courseSlug].includes(lessonSlug))
      progress[courseSlug] = progress[courseSlug].concat(`${lessonSlug}`);
  } else {
    progress[courseSlug] = [`${lessonSlug}`];
  }
  return progress;
}

export function getUpdatedQuizzResults(quizzResults, courseSlug, quizzScore) {
  if (!quizzResults) {
    return JSON.parse(`{"${courseSlug}": "${quizzScore}"}`);
  }
  quizzResults[courseSlug] = JSON.stringify(quizzScore);
  return quizzResults;
}

export const findNextLesson = (course, courseLessons) => {
  const progress = getUser().progress;
  if (progress && progress[course.Slug]) {
    const courseProgress = progress[course.Slug];
    const indexNextLesson = courseLessons
      .map((c) => c.Slug)
      .indexOf(courseProgress[courseProgress.length - 1]);
    return indexNextLesson === courseLessons.length - 1
      ? courseLessons[courseLessons.length - 1]
      : courseLessons[indexNextLesson + 1];
  } else {
    return courseLessons[0];
  }
};

export const getQuizzScore = (quizz, checkedAnswers) => {
  let score = 0;
  quizz.Questions.map((question, i) => {
    const answersIndexes = checkedAnswers[i];
    const temp = {
      0: question.correct1,
      1: question.correct2,
      2: question.correct3,
      3: question.correct4,
      4: question.correct5,
    };
    let correct = true;
    for (let j = 0; j < 5; j++) {
      if (
        (answersIndexes.includes(j) && !temp[j]) ||
        (!answersIndexes.includes(j) && temp[j])
      )
        correct = false;
    }
    if (correct) score++;
  });
  return score;
};

export const hasBeenCompleted = (currentLesson, course, progress) => {
  if (progress && progress[course.Slug]) {
    const courseProgress = progress[course.Slug];
    return courseProgress.includes(currentLesson.Slug);
  } else return false;
};
