import React from "react";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
const ProgressBar = ({ progress, total }) => {
  return (
    <div
      style={{
        height: "11px",
        width: "35%",
        backgroundColor: theme.palette.grey.servier_5,
        borderRadius: 40,
        margin: "7px 0 7px 0",
      }}
    >
      <div
        style={{
          height: "100%",
          width: `${(progress / total) * 100}%`,
          backgroundColor: theme.palette.servier.lightBlue,
          borderRadius: 40,
          textAlign: "right",
        }}
      >
        <span
          style={{
            height: "50%",
            padding: 10,
            color: "black",
            fontWeight: 900,
          }}
        ></span>
      </div>
    </div>
  );
};

export default ProgressBar;
