import { useTheme } from "@emotion/react";
import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { getUser } from "../services/auth";

const Sticky = ({ course, startOrResumeCourse }) => {
  const { t } = useI18next();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getStartOrResumeButtonLabel = () => {
    const progress = getUser().progress;
    if (!progress || !progress[course.Slug]) return t("course.startCourse");
    else return t("course.resumeCourse");
  };
  return (
    <Container
      maxWidth="false"
      sx={{
        margin: 0,
        backgroundColor: "white",
      }}
      style={{
        padding: 0,
        border: "solid",
        borderWidth: "1px 0 0 0",
        borderColor: theme.palette.grey.servier_5,
        boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Container
        sx={{
          mx: "auto",
        }}
        style={{
          paddingTop: "1em",
          paddingBottom: "1em",
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          flexDirection: isMobile ? "column" : "",
          justifyContent: "space-between",
          alignItems: isMobile ? "" : "center",
        }}
      >
        <div>
          <Typography variant="bodyStrong" style={{ marginTop: "1em" }}>
            {course.Title}
          </Typography>
          <Typography variant="body1" style={{ marginTop: "1em" }}>
            {`${t("course.duration")} : ${course.hours} ${t("course.hours")} ${
              course.minutes ? `${course.minutes} ${t("course.minutes")}` : ""
            } / ${t("course.modules")} : ${
              course.Modules ? course.Modules.length : 0
            }`}
          </Typography>
          {course.CME && (
            <Typography variant="body1" style={{ marginTop: "1em" }}>
              {`${t("course.credits")} : ${course.credits} CME`}
            </Typography>
          )}
        </div>
        <Button
          variant="contained"
          style={{
            letterSpacing: "0.1em",
            margin: isMobile ? "1em 0 1em 0" : "0 0 1em 20px",
            height: "51px",
            width: isMobile ? "" : "343px",
          }}
          onClick={startOrResumeCourse}
        >
          {getStartOrResumeButtonLabel()}
        </Button>
      </Container>
    </Container>
  );
};

export default Sticky;
