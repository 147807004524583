import { useTheme } from "@emotion/react";
import { Button, Checkbox, Chip, Container, Typography } from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import DoneIcon from "@mui/icons-material/Done";
import { getUser, setUserFromUpdateData } from "../services/auth";
import {
  getQuizzScore,
  getUpdatedProgress,
  getUpdatedQuizzResults,
} from "../utils/progress-tracking";

const Quizz = ({
  previous,
  isCourseCompleted,
  course,
  setQuizzScore,
  setQuizzHasBeenSubmitted,
  updateUser,
  checkedAnswers,
}) => {
  const theme = useTheme();
  const { t } = useI18next();

  const onCheckboxChange = (event, questionIndex, answerIndex) => {
    if (checkedAnswers && checkedAnswers[questionIndex]) {
      checkedAnswers[questionIndex] = event.target.checked
        ? checkedAnswers[questionIndex].concat(answerIndex)
        : checkedAnswers[questionIndex].filter((a) => a !== answerIndex);
    } else {
      checkedAnswers[questionIndex] = [answerIndex];
    }
  };

  const isMultiple = (question) => {
    const corrects = [];
    const temp = {
      1: question.correct1,
      2: question.correct2,
      3: question.correct3,
      4: question.correct4,
      5: question.correct5,
    };
    for (let i = 0; i < 5; i++) {
      if (temp[i]) corrects.push(i);
    }
    return corrects.length > 1;
  };
  const isQuizzIncomplete = () =>
    Object.values(checkedAnswers || {}).filter((c) => !!c.length).length <
    course.quizz.Questions.length;

  const [showIncompleteQuizzMessage, setShowIncompleteQuizzMessage] =
    useState(false);

  const validateQuizz = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    if (isQuizzIncomplete()) {
      setShowIncompleteQuizzMessage(true);
    } else {
      setShowIncompleteQuizzMessage(false);
      setQuizzScore(getQuizzScore(course.quizz, checkedAnswers));
      setQuizzHasBeenSubmitted(true);
      if (
        getQuizzScore(course.quizz, checkedAnswers) &&
        getQuizzScore(course.quizz, checkedAnswers) >= course.quizz.successScore
      ) {
        updateUser({
          variables: {
            id: getUser().id,
            progress: getUpdatedProgress(
              getUser().progress,
              course.Slug,
              course.quizz.Slug
            ),
            quizzResults: getUpdatedQuizzResults(
              getUser().quizzResults,
              course.Slug,
              getQuizzScore(course.quizz, checkedAnswers)
            ),
          },
          headers: {
            Authorization: `Bearer ${getUser().token}`,
          },
        })
          .then((data) => {
            // Handle success.
            setUserFromUpdateData(data, getUser().token);
          })
          .catch((error) => {
            // Handle error.
            console.log(
              "update user progress : an error occurred:",
              error.graphQLErrors[0]?.extensions.exception.data.message[0]
                .messages[0].message
            );
          });
      }
    }
  };
  return (
    <div>
      {isCourseCompleted && (
        <Chip
          variant="contained"
          label={t("course.completed")}
          sx={{ my: 2 }}
          icon={<DoneIcon style={{ color: "white", marginLeft: "10px" }} />}
          style={{
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "900",
            lineHeight: "19px",
            letterSpacing: "0em",
            textAlign: "left",
            backgroundColor: theme.palette.servier.green,
            color: "white",
            textTransform: "uppercase",
          }}
        />
      )}
      <Container
        fixed
        sx={{ my: 4 }}
        style={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2">{course.quizz.Title}</Typography>
        <div>
          <Button
            style={{ textTransform: "none" }}
            startIcon={
              <ArrowBackIosNew
                style={{ color: theme.palette.grey.servier_1 }}
              />
            }
            onClick={previous}
          >
            <Typography
              variant="textButton"
              style={{ color: theme.palette.grey.servier_1 }}
            >
              {t("course.previous")}
            </Typography>
          </Button>
        </div>
      </Container>
      {showIncompleteQuizzMessage && (
        <Typography
          variant="body1"
          style={{ color: theme.palette.servier.red }}
        >
          {t("course.incompleteQuizzMessage")}
        </Typography>
      )}
      {course.quizz.Questions.map((question, i) => (
        <div>
          <div style={{ margin: "50px 0 24px 0" }}>
            <Typography variant="h3">
              {t("course.question")} {i + 1}
            </Typography>
            <hr
              color={theme.palette.servier.lightBlue}
              style={{
                width: "65px",
                height: "8px",
                margin: 0,
                borderRadius: 0,
                backgroundColor: theme.palette.servier.lightBlue,
              }}
            ></hr>
          </div>
          <Typography variant="body1">{question.question}</Typography>
          <Typography
            variant="body1"
            style={{ fontStyle: "italic", marginBottom: "24px" }}
          >
            {isMultiple(question)
              ? t("course.multipleAnswers")
              : t("course.uniqueAnswer")}
          </Typography>
          {question.answer1 && (
            <div>
              <Checkbox
                checked={checkedAnswers[i] && checkedAnswers[i].includes(1)}
                onChange={(e) => onCheckboxChange(e, i, 0)}
              />
              {question.answer1}
            </div>
          )}
          {question.answer2 && (
            <div>
              <Checkbox
                checked={checkedAnswers[i] && checkedAnswers[i].includes(2)}
                onChange={(e) => onCheckboxChange(e, i, 1)}
              />
              {question.answer2}
            </div>
          )}
          {question.answer3 && (
            <div>
              <Checkbox
                checked={checkedAnswers[i] && checkedAnswers[i].includes(3)}
                onChange={(e) => onCheckboxChange(e, i, 2)}
              />
              {question.answer3}
            </div>
          )}
          {question.answer4 && (
            <div>
              <Checkbox
                checked={checkedAnswers[i] && checkedAnswers[i].includes(4)}
                onChange={(e) => onCheckboxChange(e, i, 3)}
              />
              {question.answer4}
            </div>
          )}
          {question.answer5 && (
            <div>
              <Checkbox
                checked={checkedAnswers[i] && checkedAnswers[i].includes(5)}
                onChange={(e) => onCheckboxChange(e, i, 4)}
              />
              {question.answer5}
            </div>
          )}
        </div>
      ))}
      <Container
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
          alignItems: "center",
        }}
        style={{
          padding: 0,
        }}
      >
        <Button
          variant="contained"
          sx={{
            marginTop: 8,
            marginBottom: 2,
            width: "100%",
            maxWidth: "45ch",
          }}
          onClick={validateQuizz}
        >
          {t("course.validateQuizz")}
        </Button>
      </Container>
    </div>
  );
};

export default Quizz;
