import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@emotion/react";
import DoneIcon from "@mui/icons-material/Done";
import { getUser } from "../services/auth";
import { getCourseLessons, hasBeenCompleted } from "../utils/progress-tracking";

const SyllabusLesson = ({
  course,
  currentLesson,
  setDisplayQuizz,
  setDisplayLesson,
  setCurrentLesson,
  isInCourseSummary = false,
}) => {
  const { t } = useI18next();
  const theme = useTheme();
  const progress = getUser().progress;
  return (
    <Container
      maxWidth="lg"
      sx={{
        my: { xs: 1, lg: 4 },
        mx: "auto",
      }}
      style={{ padding: 0 }}
    >
      <div style={{ marginBottom: "2em" }}>
        <Typography variant="h3">{t("course.syllabus")}</Typography>
        <hr
          color={theme.palette.servier.lightBlue}
          style={{
            width: "65px",
            height: "8px",
            margin: "0",
          }}
        ></hr>
      </div>

      {course &&
        course.Modules &&
        course.Modules.map((content, i) => (
          <Accordion
            style={{
              margin: 0,
              borderRadius: 0,
              boxShadow: "none",
              border: "solid",
              borderWidth: "1px 0 0 0",
              borderColor: theme.palette.grey.servier_5,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                px: { xs: 0, md: 2 },
              }}
            >
              <Typography variant="subtitle1">{content.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {content.lessons &&
                content.lessons.map((lesson, i) => (
                  <div style={{ display: "flex" }}>
                    {hasBeenCompleted(lesson, course, progress) && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <DoneIcon />
                      </div>
                    )}

                    <Button
                      onClick={() => {
                        setCurrentLesson(lesson);
                        setDisplayLesson(true);
                      }}
                      disabled={
                        !hasBeenCompleted(lesson, course, progress) &&
                        currentLesson.Slug !== lesson.Slug
                      }
                      style={{
                        textTransform: "none",
                        color:
                          currentLesson.Slug === lesson.Slug &&
                          !isInCourseSummary
                            ? theme.palette.servier.lightBlue
                            : !hasBeenCompleted(lesson, course, progress) &&
                              currentLesson.Slug !== lesson.Slug
                            ? theme.palette.grey.servier_4
                            : "black",
                        backgroundColor: "white",
                        paddingLeft: hasBeenCompleted(lesson, course, progress)
                          ? "9px"
                          : "32px",
                      }}
                    >
                      {lesson.title}
                    </Button>
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
      {course && course.quizz && (
        <AccordionSummary
          style={{
            margin: 0,
            borderRadius: 0,
            boxShadow: "none",
            border: "solid",
            borderWidth: "1px 0 1px 0",
            borderColor: theme.palette.grey.servier_4,
          }}
          sx={{
            px: { xs: 0, md: 2 },
          }}
          onClick={() => {
            setDisplayQuizz(true);
            setDisplayLesson(false);
          }}
          disabled={
            !progress ||
            !progress[course.Slug] ||
            !hasBeenCompleted(
              getCourseLessons(course)[getCourseLessons(course).length - 1],
              course,
              getUser().progress
            )
          }
        >
          <Typography variant="subtitle1">{course.quizz.Title}</Typography>
        </AccordionSummary>
      )}
    </Container>
  );
};

export default SyllabusLesson;
