import { useTheme } from "@emotion/react";
import { Button, Container, Typography } from "@mui/material";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { downloadFile } from "../utils";
import CustomButtonTextAndIcon from "./button/custom-button-icon";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { navigate } from "gatsby";

const QuizzResults = ({
  setDisplayLesson,
  setDisplayQuizz,
  setQuizzHasBeenSubmitted,
  isCourseCompleted,
  quizzScore,
  course,
}) => {
  const theme = useTheme();
  const { t, language } = useI18next();
  const onClickBackToCourses = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/courses/`);
  };
  return (
    <div>
      {!isCourseCompleted ? (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {quizzScore >= course.quizz.successScore ? (
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {" "}
              <div>
                <Typography
                  variant="h1"
                  style={{ textAlign: "center", margin: "20px 0" }}
                >
                  {t("course.congratulations")}
                </Typography>
              </div>
              <div>
                <Typography variant="h3" style={{ textAlign: "center" }}>
                  {t("course.youGot")}{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    {quizzScore}/{course.quizz.Questions.length}
                  </span>{" "}
                  {t("course.onYourTest")} {course.Title}
                </Typography>
              </div>
              {course.CME && (
                <div
                  style={{
                    margin: "50px 0",
                    maxWidth: "532px",
                  }}
                >
                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    {t("course.letUsProcess")}
                  </Typography>
                </div>
              )}
              {course.diploma && (
                <CustomButtonTextAndIcon
                  onClick={() =>
                    downloadFile(course.diploma.name, course.diploma)
                  }
                  startIcon={<FileDownloadIcon />}
                  text={t("course.downloadDiploma")}
                  width="255px"
                />
              )}
            </Container>
          ) : (
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Typography variant="h1">{t("course.letsTryAgain")}</Typography>
              </div>
              <div>
                <Typography
                  variant="h3"
                  style={{ textAlign: "center", marginTop: "25px" }}
                >
                  {t("course.youGot")}{" "}
                  <span style={{ color: theme.palette.error.main }}>
                    {quizzScore}/{course.quizz.Questions.length}
                  </span>{" "}
                  {t("course.onYourTest")} {course.Title}
                </Typography>
              </div>
              <div
                style={{
                  margin: "50px 0",
                  maxWidth: "532px",
                }}
              >
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  {t("course.dontWorry")}
                </Typography>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  setDisplayQuizz(true);
                  setDisplayLesson(false);
                  setQuizzHasBeenSubmitted(false);
                }}
              >
                {t("course.tryAgain")}
              </Button>
            </Container>
          )}
        </Container>
      ) : (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <Typography variant="h1" style={{ textAlign: "center" }}>
              {t("course.youAlreadyPassed")}
            </Typography>
          </div>
          {course.CME && (
            <div
              style={{
                margin: "50px 0",
                maxWidth: "532px",
              }}
            >
              <Typography variant="body1" style={{ textAlign: "center" }}>
                {t("course.youDidNotReceive")}
              </Typography>
            </div>
          )}
          {course.diploma && (
            <CustomButtonTextAndIcon
              onClick={() => downloadFile(course.diploma.name, course.diploma)}
              startIcon={<FileDownloadIcon />}
              text={t("course.downloadDiploma")}
              width="255px"
            />
          )}
        </Container>
      )}
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button style={{ margin: "20px 0" }} onClick={onClickBackToCourses}>
          {t("course.backToCourses")}
        </Button>
      </Container>
    </div>
  );
};

export default QuizzResults;
